// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { THEME_EXTRA } from '../../../helpers/theme'
import { createTheme } from '@mui/material/styles'
import { useTheme } from '@emotion/react'
import Box from '@mui/material/Box'
import MqSmallIcon from '../small-icon/MqSmallIcon'
import MqText from '../text/MqText'
import React from 'react'

interface MqChipProps {
  selected?: boolean
  onSelect?: (label: string) => void
  icon?: IconDefinition
  foregroundColor?: string
  backgroundColor?: string
  text?: string
  value: string
  selectable?: boolean
}

/**
 * This is a simple button that can be either selected or unselected. Is is configurable with icons and/or text
 */
const MqChip: React.FC<MqChipProps> = ({
  selected,
  onSelect,
  icon,
  text,
  value,
  foregroundColor,
  backgroundColor,
  selectable,
}) => {
  const theme = createTheme(useTheme())

  const styleObject = {
    display: 'inline-block',
    padding: '2px 12px',
    borderRadius: theme.spacing(1),
    cursor: value.toLowerCase() === 'sort' ? 'auto' : 'pointer',
    userSelect: 'none',
    boxShadow: `0 0 1px 0px ${theme.palette.grey[300]}`,
  }

  return (
    <Box
      id={`chip-${value}`}
      sx={{
        ...styleObject,
        ...(selected
          ? {
              borderRadius: theme.spacing(1),
              boxShadow: `0 0 2px 1px ${theme.palette.common.black}`,
              backgroundColor: THEME_EXTRA.background.tableHeader,
            }
          : {}),
      }}
      onClick={() => {
        if (selectable !== false && onSelect) {
          onSelect(value)
        }
      }}
    >
      {icon && foregroundColor && backgroundColor && (
        <Box display={'inherit'}>
          <MqSmallIcon
            icon={icon}
            shape={'rect'}
            foregroundColor={foregroundColor}
            backgroundColor={backgroundColor}
          />
        </Box>
      )}
      {text && (
        <Box ml={icon ? 1 : 0} display={'inline'}>
          <MqText inline>{text}</MqText>
        </Box>
      )}
    </Box>
  )
}

export default MqChip
