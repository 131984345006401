// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import { ArrowBackIosRounded } from '@mui/icons-material'
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Run } from '../../types/api'
import { THEME_EXTRA } from '../../helpers/theme'
import { alpha, createTheme } from '@mui/material/styles'
import { formatUpdatedAt } from '../../helpers'
import { runStateColor } from '../../helpers/nodes'
import { stopWatchDuration } from '../../helpers/time'
import { useTheme } from '@emotion/react'
import MqCode from '../core/code/MqCode'
import MqCopy from '../core/copy/MqCopy'
import MqEmpty from '../core/empty/MqEmpty'
import MqStatus from '../core/status/MqStatus'
import MqText from '../core/text/MqText'
import React, { FunctionComponent, SetStateAction } from 'react'
import RunInfo from './RunInfo'
import {
  TABLE_CONTAINER,
  TABLE_CELL,
  TABLE_HEADER,
  TABLE_HEADER_BORDER,
} from '../../helpers/commonStyles'

interface RunsProps {
  runs: Run[]
  facets?: object
}

const Runs: FunctionComponent<RunsProps> = (props) => {
  const { runs, facets } = props
  const i18next = require('i18next')
  if (runs.length === 0) {
    return <MqEmpty title={i18next.t('jobs.empty_title')} body={i18next.t('jobs.empty_body')} />
  }

  const [infoView, setInfoView] = React.useState<Run | null>(null)
  const handleClick = (newValue: SetStateAction<Run | null>) => {
    setInfoView(newValue)
  }

  const theme = createTheme(useTheme())

  const styleObject = {
    chip: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      border: `1px solid ${THEME_EXTRA.border.tableHeader}`,
    },
  }

  if (infoView) {
    return (
      <>
        <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
          <Chip size={'small'} variant={'outlined'} label={infoView.id} sx={styleObject.chip} />
          <IconButton
            onClick={() => handleClick(null)}
            size='small'
            style={{ color: theme.palette.primary.main }}
          >
            <ArrowBackIosRounded fontSize={'small'} />
          </IconButton>
        </Box>
        <RunInfo run={infoView} />
      </>
    )
  }

  return (
    <Box>
      <TableContainer sx={TABLE_CONTAINER}>
        <Table size='small'>
          <TableHead sx={TABLE_HEADER}>
            <TableRow>
              <TableCell align='left' sx={TABLE_HEADER_BORDER}>
                <MqText subheading inline>
                  {i18next.t('runs_columns.id')}
                </MqText>
              </TableCell>
              <TableCell align='left' sx={TABLE_HEADER_BORDER}>
                <MqText subheading inline>
                  {i18next.t('runs_columns.state')}
                </MqText>
              </TableCell>
              <TableCell align='left' sx={TABLE_HEADER_BORDER}>
                <MqText subheading inline>
                  {i18next.t('runs_columns.created_at')}
                </MqText>
              </TableCell>
              <TableCell align='left' sx={TABLE_HEADER_BORDER}>
                <MqText subheading inline>
                  {i18next.t('runs_columns.started_at')}
                </MqText>
              </TableCell>
              <TableCell align='left' sx={TABLE_HEADER_BORDER}>
                <MqText subheading inline>
                  {i18next.t('runs_columns.ended_at')}
                </MqText>
              </TableCell>
              <TableCell align='left' sx={TABLE_HEADER_BORDER}>
                <MqText subheading inline>
                  {i18next.t('runs_columns.duration')}
                </MqText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {runs.map((run) => {
              return run.durationMs > 0 ? (
                <TableRow
                  key={run.id}
                  sx={{
                    cursor: 'pointer',
                    transition: theme.transitions.create(['background-color']),
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.common.white, 0.1),
                    },
                  }}
                  onClick={() => handleClick(run)}
                >
                  <TableCell align='left' sx={TABLE_CELL}>
                    <Box display={'flex'} alignItems={'center'}>
                      {run.id.substring(0, 8)}...
                      <MqCopy string={run.id} />
                    </Box>
                  </TableCell>
                  <TableCell align='left' sx={TABLE_CELL}>
                    <MqStatus color={runStateColor(run.state)} label={run.state} />
                  </TableCell>
                  <TableCell align='left' sx={TABLE_CELL}>
                    {formatUpdatedAt(run.createdAt)}
                  </TableCell>
                  <TableCell align='left' sx={TABLE_CELL}>
                    {formatUpdatedAt(run.startedAt)}
                  </TableCell>
                  <TableCell align='left' sx={TABLE_CELL}>
                    {formatUpdatedAt(run.endedAt)}
                  </TableCell>
                  <TableCell align='left' sx={TABLE_CELL}>
                    {stopWatchDuration(run.durationMs)}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow
                  key={run.id}
                  sx={{
                    cursor: 'pointer',
                    transition: theme.transitions.create(['background-color']),
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.common.white, 0.1),
                    },
                  }}
                  onClick={() => handleClick(run)}
                >
                  <TableCell align='left' sx={TABLE_CELL}>
                    {run.id}
                  </TableCell>
                  <TableCell align='left' sx={TABLE_CELL}>
                    <MqStatus color={runStateColor(run.state)} label={run.state} />
                  </TableCell>
                  <TableCell align='left' sx={TABLE_CELL}>
                    {formatUpdatedAt(run.createdAt)}
                  </TableCell>
                  <TableCell align='left' sx={TABLE_CELL}>
                    {formatUpdatedAt(run.startedAt)}
                  </TableCell>
                  <TableCell align='left' sx={TABLE_CELL}>
                    N/A
                  </TableCell>
                  <TableCell align='left' sx={TABLE_CELL}>
                    {stopWatchDuration(run.durationMs)}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {facets && (
        <Box mt={2}>
          <Box mb={1}>
            <MqText subheading>{i18next.t('jobs.runs_subhead')}</MqText>
          </Box>
          <MqCode code={JSON.stringify(facets, null, '\t')} />
        </Box>
      )}
    </Box>
  )
}

export default Runs
