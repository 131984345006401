// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import { createTheme } from '@mui/material'

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          body: {
            color: '#fff',
          },
        },
        '.MuiInputBase-root': {
          paddingTop: '0',
          paddingBottom: '0',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    fontSize: 14,
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#FE6802',
    },
    error: {
      main: '#FF0000',
    },
    warning: {
      main: '#7D7D7D',
    },
    info: {
      main: '#FECC00',
    },
    background: {
      default: '#FFFFFF',
    },
    secondary: {
      main: '#454F5B',
    },
  },
  zIndex: {
    snackbar: 9999,
  },
})

export const THEME_EXTRA = {
  typography: {
    subdued: '#ABB1BD',
    disabled: '#8D9499',
    succeeded: '#12B76A',
    code: '#1E1E1E',
    common: '#212121',
    link: '#5181DE',
    headerMenuLight: 'rgba(33, 33, 33, 0.50)',
    headerMenuDark: 'rgba(33, 33, 33, 0.70)',
  },
  hover: { sidebar: '#FE8E42' },
  border: {
    table: 'rgba(33, 33, 33, 0.15)',
    tableHeader: 'rgba(0, 0, 0, 0.15)',
  },
  background: {
    header: '#F9F9FB',
    sidebar: '#FAFAFA',
    tableHeader: '#F1F1F1',
    scrollbar: '#EBECF0',
  },
}

// 1px for bottom border
// export const HEADER_HEIGHT = 96 + 1
// export const DRAWER_WIDTH = 96

// Previous configuration
// export const HEADER_HEIGHT = 100
// export const DRAWER_WIDTH = 172

// Latest configuration
export const HEADER_HEIGHT = 64 + 1
// export const DRAWER_WIDTH = 80
export const DRAWER_WIDTH = 160
