// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import SVG from 'react-inlinesvg'

import { Link, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'

import { DRAWER_WIDTH, HEADER_HEIGHT, THEME_EXTRA } from '../../helpers/theme'
import MqIconButton from '../core/icon-button/MqIconButton'
import MqText from '../core/text/MqText'
import databaseActiveIcon from './../../img/databaseActive.svg'
import databaseIcon from './../../img/database.svg'
import eventActiveIcon from './../../img/eventActive.svg'
import eventIcon from './../../img/event.svg'
import jobActiveIcon from './../../img/jobActive.svg'
import jobIcon from './../../img/job.svg'

// for i18n
import '../../i18n/config'
import ubix_logo from '../../img/ubix.svg'

const styleObject = {
  drawer: {
    marginTop: `${HEADER_HEIGHT}px`,
    width: `${DRAWER_WIDTH}px`,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    // '& > :first-child': { borderRight: 'none' },
    '& > :first-of-type': { borderRight: 'none' },
  },
  background: {
    backgroundColor: THEME_EXTRA.background.sidebar,
    borderBottom: '1px solid #F2F2F2',
  },
  link: { textDecoration: 'none' },
}

interface SidenavProps {}

const Sidenav: React.FC<SidenavProps> = () => {
  const i18next = require('i18next')

  const location = useLocation()

  const isJobActive = location.pathname === '/' || location.pathname.includes('/lineage/job/')
  const isDatasetActive = location.pathname.includes('/dataset')
  const isEventActive = ['/events', '/events/'].includes(location.pathname)

  return (
    <Box
      position={'relative'}
      width={'100%'}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      height={'100%'}
      sx={styleObject.background}
    >
      <Box
        display={'flex'}
        width={'100%'}
        paddingTop={'0.5rem'}
        paddingLeft={'2.5rem'}
        paddingRight={'1rem'}
      >
        <MqIconButton
          to={'/'}
          id={'homeDrawerButton'}
          title={i18next.t('sidenav.jobs')}
          active={isJobActive}
        >
          {isJobActive ? (
            <>
              <SVG src={jobActiveIcon} width={'1.5rem'} height={'1.5rem'} />
              <MqText activatedSideBar>Jobs</MqText>
            </>
          ) : (
            <>
              <SVG src={jobIcon} width={'1.5rem'} height={'1.5rem'} />
              <MqText sidebarTab>Jobs</MqText>
            </>
          )}
        </MqIconButton>
        <MqIconButton
          to={'/datasets'}
          id={'datasetsDrawerButton'}
          title={i18next.t('sidenav.datasets')}
          active={isDatasetActive}
        >
          {isDatasetActive ? (
            <>
              <SVG src={databaseActiveIcon} width={'1.5rem'} height={'1.5rem'} />
              <MqText activatedSideBar>Datasets</MqText>
            </>
          ) : (
            <>
              <SVG src={databaseIcon} width={'1.5rem'} height={'1.5rem'} />
              <MqText sidebarTab>Datasets</MqText>
            </>
          )}
        </MqIconButton>
        <MqIconButton
          id={'eventsButton'}
          to={'/events'}
          title={i18next.t('sidenav.events')}
          active={isEventActive}
        >
          {isEventActive ? (
            <>
              <SVG src={eventActiveIcon} width={'1.5rem'} height={'1.5rem'} />
              <MqText activatedSideBar>Events</MqText>
            </>
          ) : (
            <>
              <SVG src={eventIcon} width={'1.5rem'} height={'1.5rem'} />
              <MqText sidebarTab>Events</MqText>
            </>
          )}
        </MqIconButton>
      </Box>
    </Box>
  )
}

export default Sidenav
