// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import { Box } from '@mui/material'
import { THEME_EXTRA, theme } from '../../helpers/theme'
import MqText from '../core/text/MqText'
import React from 'react'
import Typewriter from './Typewriter'

const importI18next = () => {
  return require('i18next')
}

interface SearchPlaceholderProps {}

const SearchPlaceholder: React.FC<SearchPlaceholderProps> = () => {
  const i18next = importI18next()
  return (
    <Box
      sx={{
        zIndex: theme.zIndex.appBar + 3,
        position: 'absolute',
        left: 128,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        overflow: 'visible',
        pointerEvents: 'none',
      }}
    >
      <Box display={'inline'}>
        <MqText
          disabled
          inline
          aria-label={i18next.t('search.search_area')}
          aria-required='true'
          color={THEME_EXTRA.typography.headerMenuLight}
        >
          Search your
        </MqText>
        <MqText bold inline color={THEME_EXTRA.typography.headerMenuDark}>
          {' '}
          <Typewriter words={['Jobs and Datasets…']} repeatCount={3} />
        </MqText>
      </Box>
    </Box>
  )
}

export default SearchPlaceholder
