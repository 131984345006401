import { ArrowBackIosRounded, Refresh } from '@mui/icons-material'
import { Divider, TextField } from '@mui/material'
import { THEME_EXTRA, theme } from '../../helpers/theme'
import { fetchColumnLineage } from '../../store/actionCreators'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MQTooltip from '../../components/core/tooltip/MQTooltip'
import MqText from '../../components/core/text/MqText'
import React from 'react'

interface ActionBarProps {
  fetchColumnLineage: typeof fetchColumnLineage
  depth: number
  setDepth: (depth: number) => void
}

export const ActionBar = ({ fetchColumnLineage, depth, setDepth }: ActionBarProps) => {
  const { namespace, name } = useParams()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Box
      sx={{
        borderBottomWidth: 1,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: 'solid',
      }}
      display={'flex'}
      height={'64px'}
      justifyContent={'space-between'}
      alignItems={'center'}
      px={2}
      borderColor={THEME_EXTRA.border.tableHeader}
    >
      <Box display={'flex'} alignItems={'center'}>
        <MQTooltip title={'Back to datasets'}>
          <IconButton
            size={'small'}
            sx={{ mr: 2, color: theme.palette.primary.main }}
            onClick={() => navigate('/datasets')}
          >
            <ArrowBackIosRounded fontSize={'small'} />
          </IconButton>
        </MQTooltip>
        <MqText heading>Datasets</MqText>
        <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
        <Box>
          <MqText disabled>Mode</MqText>
          <MqText>Column Level</MqText>
        </Box>
        <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
        <Box>
          <MqText disabled>Workspace</MqText>
          <MqText>{namespace || 'Unknown workspace name'}</MqText>
        </Box>
        <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
        <Box>
          <MqText disabled>Name</MqText>
          <MqText>{name || 'Unknown dataset name'}</MqText>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <MQTooltip title={'Refresh'}>
          <IconButton
            sx={{ mr: 2 }}
            color={'primary'}
            size={'small'}
            onClick={() => {
              if (namespace && name) {
                fetchColumnLineage('DATASET', namespace, name, depth)
              }
            }}
          >
            <Refresh fontSize={'small'} />
          </IconButton>
        </MQTooltip>
        <TextField
          id='column-level-depth'
          type='number'
          label='Depth'
          variant='outlined'
          size='small'
          sx={{ width: '80px' }}
          value={depth}
          InputProps={{ style: { color: theme.palette.common.black } }}
          InputLabelProps={{ style: { color: theme.palette.primary.main } }}
          onChange={(e) => {
            setDepth(isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value))
            searchParams.set('depth', e.target.value)
            setSearchParams(searchParams)
          }}
        />
      </Box>
    </Box>
  )
}
