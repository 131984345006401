import * as Redux from 'redux'
import { Box } from '@mui/system'
import {
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { ColumnLineageGraph, Dataset } from '../../types/api'
import { IState } from '../../store/reducers'
import { THEME_EXTRA, theme } from '../../helpers/theme'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchDataset } from '../../store/actionCreators'
import { useSearchParams } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import MqJsonView from '../../components/core/json-view/MqJsonView'
import MqText from '../../components/core/text/MqText'
import React, { useEffect } from 'react'
import SVG from 'react-inlinesvg'
import databaseActiveIcon from './../../img/databaseActive.svg'
import {
  TABLE_CELL,
  TABLE_CONTAINER,
  TABLE_HEADER,
  TABLE_HEADER_BORDER,
} from '../../helpers/commonStyles'

const i18next = require('i18next')

const WIDTH = 800

interface StateProps {
  dataset: Dataset
  isDatasetLoading: boolean
  columnLineage: ColumnLineageGraph
}
interface DispatchProps {
  fetchDataset: typeof fetchDataset
}
const ColumnLevelDrawer = ({
  dataset,
  fetchDataset,
  columnLineage,
  isDatasetLoading,
}: StateProps & DispatchProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    const dataset = searchParams.get('dataset')
    const namespace = searchParams.get('namespace')
    if (dataset && namespace) {
      fetchDataset(namespace, dataset)
    }
  }, [])

  if (!columnLineage) {
    return null
  }

  return (
    <Box width={`${WIDTH}px`}>
      <Box
        position={'sticky'}
        top={0}
        bgcolor={theme.palette.background.default}
        pt={2}
        zIndex={theme.zIndex.appBar}
        sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
      >
        <Box px={2} pb={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'}>
            <Box display={'flex'} alignItems={'center'}>
              <Box
                mr={1}
                borderRadius={theme.spacing(1)}
                width={32}
                height={32}
                display={'flex'}
                alignItems={'center'}
              >
                <SVG src={databaseActiveIcon} width={'1.5rem'} height={'1.5rem'} />
              </Box>
            </Box>
            <MqText heading>{searchParams.get('dataset')}</MqText>
          </Box>
          <IconButton
            onClick={() => setSearchParams({})}
            sx={{ color: THEME_EXTRA.typography.disabled }}
          >
            <CloseIcon fontSize={'small'} />
          </IconButton>
        </Box>
      </Box>
      {!dataset || isDatasetLoading ? (
        <Box mt={2} display={'flex'} justifyContent={'center'}>
          <CircularProgress color='primary' />
        </Box>
      ) : (
        <>
          <Box sx={{ padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)}` }}>
            <MqText subheading>SCHEMA</MqText>
          </Box>
          {dataset.fields.length > 0 && (
            <Box sx={{ padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)}` }}>
              <TableContainer sx={TABLE_CONTAINER}>
                <Table size='small'>
                  <TableHead sx={TABLE_HEADER}>
                    <TableRow>
                      <TableCell align='left' sx={TABLE_HEADER_BORDER}>
                        <MqText subheading inline>
                          {i18next.t('dataset_info_columns.name')}
                        </MqText>
                      </TableCell>
                      <TableCell align='left' sx={TABLE_HEADER_BORDER}>
                        <MqText subheading inline>
                          {i18next.t('dataset_info_columns.type')}
                        </MqText>
                      </TableCell>
                      <TableCell align='left' sx={TABLE_HEADER_BORDER}>
                        <MqText subheading inline>
                          {i18next.t('dataset_info_columns.description')}
                        </MqText>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataset.fields.map((field) => {
                      return (
                        <React.Fragment key={field.name}>
                          <TableRow>
                            <TableCell align='left' sx={TABLE_CELL}>
                              <MqText>{field.name}</MqText>
                            </TableCell>
                            <TableCell align='left' sx={TABLE_CELL}>
                              <Chip
                                size={'small'}
                                label={<MqText>{field.type.toUpperCase()}</MqText>}
                                variant={'outlined'}
                              />
                            </TableCell>
                            <TableCell align='left' sx={TABLE_CELL}>
                              <MqText subdued>{field.description || 'no description'}</MqText>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </>
      )}
      {dataset && dataset.columnLineage && (
        <>
          <Box sx={{ padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)}` }}>
            <MqText subheading>FACETS</MqText>
            <MqJsonView data={dataset.columnLineage} />
          </Box>
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state: IState) => ({
  dataset: state.dataset.result,
  isDatasetLoading: state.dataset.isLoading,
  columnLineage: state.columnLineage.columnLineage,
})

const mapDispatchToProps = (dispatch: Redux.Dispatch) =>
  bindActionCreators(
    {
      fetchDataset: fetchDataset,
    },
    dispatch
  )
export default connect(mapStateToProps, mapDispatchToProps)(ColumnLevelDrawer)
