// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import React, { ReactElement } from 'react'

import { Link as RouterLink } from 'react-router-dom'
import { theme } from '../../../helpers/theme'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'

const styleObject = {
  root: {
    color: theme.palette.background.default,
    transition: theme.transitions.create(['background-color', 'color']),
    border: '2px solid transparent',
    justifyContent: 'flex-start',
    padding: '0.75rem 0.75rem 1rem 1rem',
    gap: '0.5rem',
    // '&:hover': {
    //   background: 'rgba(236, 114, 46, 0.08)',
    //   width: '100%',
    // },
  },
  active: {
    // background: 'rgba(236, 114, 46, 0.08)',
    color: theme.palette.primary.main,
    width: '100%',
    borderBottom: `2px solid ${theme.palette.primary.main}`
    // '&::before': {
    //   position: 'absolute',
    //   content: `''`,
    //   width: '4px',
    //   height: '56px',
    //   flexShrink: 0,
    //   borderRadius: '0px 8px 8px 0px',
    //   background: theme.palette.primary.main,
    //   left: '-18px',
    // },
  },
  iconButtonContainer: {
    color: 'transparent',
    // paddingRight: '0.5rem',
    transition: theme.transitions.create(['color']),
    // '&:hover': {
    //   color: theme.palette.primary.main,
    // },
  },
}

interface OwnProps {
  id: string
  title: string
  children: ReactElement
  active: boolean
  to: string
}

type IconButtonProps = OwnProps

const MqIconButton: React.FC<IconButtonProps> = ({ id, title, active, children, to }) => {
  return (
    <Box sx={styleObject.iconButtonContainer}>
      <ButtonBase
        id={id}
        component={RouterLink}
        to={to}
        disableRipple={true}
        sx={{ ...styleObject.root, ...(active ? styleObject.active : {}) }}
      >
        {children}
      </ButtonBase>
    </Box>
  )
}

export default MqIconButton
