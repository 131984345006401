// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import * as Redux from 'redux'
import { Box, Divider, Grid, Tab, Tabs, createTheme } from '@mui/material'
import { CalendarIcon } from '@mui/x-date-pickers'
import { CircularProgress } from '@mui/material'
import { Dataset, DatasetVersion } from '../../types/api'
import { IState } from '../../store/reducers'
import { LineageDataset } from '../../types/lineage'
import { MqInfo } from '../core/info/MqInfo'
import { THEME_EXTRA } from '../../helpers/theme'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { datasetFacetsQualityAssertions, datasetFacetsStatus } from '../../helpers/nodes'
import {
  deleteDataset,
  dialogToggle,
  fetchDataset,
  fetchDatasetVersions,
  resetDataset,
  resetDatasetVersions,
  setTabIndex,
} from '../../store/actionCreators'
import { formatUpdatedAt } from '../../helpers'
import { truncateText } from '../../helpers/text'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import Assertions from './Assertions'
import CloseIcon from '@mui/icons-material/Close'
import DatasetInfo from './DatasetInfo'
// import DatasetTags from './DatasetTags'
import DatasetVersions from './DatasetVersions'
import IconButton from '@mui/material/IconButton'
import ListIcon from '@mui/icons-material/List'
import MQTooltip from '../core/tooltip/MQTooltip'
import MqStatus from '../core/status/MqStatus'
import MqText from '../core/text/MqText'
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react'
import RuleIcon from '@mui/icons-material/Rule'
import SVG from 'react-inlinesvg'
import StorageIcon from '@mui/icons-material/Storage'
import databaseActiveIcon from './../../img/databaseActive.svg'

interface StateProps {
  lineageDataset: LineageDataset
  dataset: Dataset
  versions: DatasetVersion[]
  versionsLoading: boolean
  datasets: IState['datasets']
  display: IState['display']
  tabIndex: IState['lineage']['tabIndex']
}

interface DispatchProps {
  fetchDatasetVersions: typeof fetchDatasetVersions
  fetchDataset: typeof fetchDataset
  resetDatasetVersions: typeof resetDatasetVersions
  resetDataset: typeof resetDataset
  deleteDataset: typeof deleteDataset
  dialogToggle: typeof dialogToggle
  setTabIndex: typeof setTabIndex
}

type IProps = StateProps & DispatchProps

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const DatasetDetailPage: FunctionComponent<IProps> = (props) => {
  const {
    datasets,
    dataset,
    display,
    fetchDataset,
    fetchDatasetVersions,
    resetDataset,
    resetDatasetVersions,
    deleteDataset,
    dialogToggle,
    versions,
    versionsLoading,
    lineageDataset,
    tabIndex,
    setTabIndex,
  } = props
  const navigate = useNavigate()
  const i18next = require('i18next')
  const theme = createTheme(useTheme())
  const [_, setSearchParams] = useSearchParams()
  const [showTags, setShowTags] = useState(false)

  // unmounting
  useEffect(
    () => () => {
      resetDataset()
      resetDatasetVersions()
    },
    []
  )

  useEffect(() => {
    fetchDatasetVersions(lineageDataset.namespace, lineageDataset.name)
    fetchDataset(lineageDataset.namespace, lineageDataset.name)
  }, [lineageDataset.name, showTags])

  // if the dataset is deleted then redirect to datasets end point
  useEffect(() => {
    if (datasets.deletedDatasetName) {
      navigate('/datasets')
    }
  }, [datasets.deletedDatasetName])

  const handleChange = (_: ChangeEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  if (versionsLoading && versions.length === 0) {
    return (
      <Box display={'flex'} justifyContent={'center'} mt={2}>
        <CircularProgress color='primary' />
      </Box>
    )
  }

  if (versions.length === 0) {
    return null
  }

  const firstVersion = versions[0]
  const { name, tags, description } = firstVersion
  const facetsStatus = datasetFacetsStatus(firstVersion.facets)

  const assertions = datasetFacetsQualityAssertions(firstVersion.facets)

  return (
    <Box px={2}>
      <Box
        position={'sticky'}
        top={0}
        bgcolor={theme.palette.background.default}
        pt={2}
        zIndex={theme.zIndex.appBar}
        sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
        mb={2}
      >
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} pb={2}>
          <Box display={'flex'} alignItems={'center'}>
            <Box display={'flex'} alignItems={'center'}>
              <Box
                mr={1}
                borderRadius={theme.spacing(1)}
                width={32}
                height={32}
                display={'flex'}
                alignItems={'center'}
              >
                <SVG src={databaseActiveIcon} width={'1.5rem'} height={'1.5rem'} />
              </Box>
              <MqText heading>{truncateText(name, 40)}</MqText>
            </Box>
            <MqText subdued>{description}</MqText>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <IconButton
              onClick={() => setSearchParams({})}
              sx={{ color: THEME_EXTRA.typography.disabled }}
            >
              <CloseIcon fontSize={'small'} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MqInfo
            icon={<CalendarIcon sx={{ color: THEME_EXTRA.typography.disabled }} />}
            label={'Updated at'.toUpperCase()}
            value={formatUpdatedAt(firstVersion.createdAt)}
          />
        </Grid>
        <Grid item xs={6}>
          <MqInfo
            icon={<StorageIcon sx={{ color: THEME_EXTRA.typography.disabled }} />}
            label={'Dataset Type'.toUpperCase()}
            value={<MqText bold>{firstVersion.type}</MqText>}
          />
        </Grid>
        <Grid item xs={6}>
          <MqInfo
            icon={<ListIcon sx={{ color: THEME_EXTRA.typography.disabled }} />}
            label={'Fields'.toUpperCase()}
            value={`${firstVersion.fields.length} columns`}
          />
        </Grid>
        <Grid item xs={6}>
          <MqInfo
            icon={<RuleIcon sx={{ color: THEME_EXTRA.typography.disabled }} />}
            label={'Quality'.toUpperCase()}
            value={
              facetsStatus ? (
                <Box display={'flex'}>
                  <MQTooltip
                    title={
                      <Assertions
                        assertions={assertions.filter((assertion) => assertion.success)}
                      />
                    }
                  >
                    <Box>
                      <MqStatus
                        label={`${
                          assertions.filter((assertion) => assertion.success).length
                        } Passing`.toUpperCase()}
                        color={theme.palette.primary.main}
                      />
                    </Box>
                  </MQTooltip>
                  <Divider sx={{ mx: 1 }} orientation={'vertical'} />
                  <MQTooltip
                    title={
                      <Assertions
                        assertions={assertions.filter((assertion) => !assertion.success)}
                      />
                    }
                  >
                    <Box>
                      <MqStatus
                        label={`${
                          assertions.filter((assertion) => !assertion.success).length
                        } Failing`.toUpperCase()}
                        color={theme.palette.error.main}
                      />
                    </Box>
                  </MQTooltip>
                </Box>
              ) : (
                <MqStatus label={'N/A'} color={theme.palette.secondary.main} />
              )
            }
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      {/* <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <DatasetTags
          datasetTags={tags}
          datasetName={lineageDataset.name}
          namespace={lineageDataset.namespace}
        />
      </Box> */}
      <Box display={'flex'} justifyContent={'space-between'} mb={2}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            textColor='primary'
            indicatorColor='primary'
          >
            <Tab
              label={i18next.t('datasets.latest_tab')}
              {...a11yProps(0)}
              disableRipple={true}
              sx={{ color: theme.palette.common.black }}
            />
            <Tab
              label={i18next.t('datasets.history_tab')}
              {...a11yProps(2)}
              disableRipple={true}
              sx={{ color: theme.palette.common.black }}
            />
          </Tabs>
        </Box>
        {/* {tabIndex === 0 && (
          <Box display={'flex'} alignItems={'center'}>
            <FormControlLabel
              sx={{
                textWrap: 'nowrap',
                '& .MuiFormControlLabel-label': { fontSize: '0.875rem' },
              }}
              control={
                <Switch
                  size={'small'}
                  checked={showTags}
                  onChange={() => setShowTags(!showTags)}
                  inputProps={{ 'aria-label': 'toggle show tags' }}
                  disabled={versionsLoading}
                />
              }
              label={i18next.t('datasets.show_field_tags')}
            />
          </Box>
        )} */}
      </Box>
      {tabIndex === 0 && (
        <DatasetInfo
          dataset={dataset}
          datasetFields={firstVersion.fields}
          facets={firstVersion.facets}
          run={firstVersion.createdByRun}
          showTags={showTags}
          isCurrentVersion
        />
      )}
      {tabIndex === 1 && <DatasetVersions dataset={dataset} versions={props.versions} />}
    </Box>
  )
}

const mapStateToProps = (state: IState) => ({
  datasets: state.datasets,
  dataset: state.dataset.result,
  display: state.display,
  versions: state.datasetVersions.result.versions,
  versionsLoading: state.datasetVersions.isLoading,
  tabIndex: state.lineage.tabIndex,
})

const mapDispatchToProps = (dispatch: Redux.Dispatch) =>
  bindActionCreators(
    {
      fetchDatasetVersions: fetchDatasetVersions,
      fetchDataset: fetchDataset,
      resetDatasetVersions: resetDatasetVersions,
      resetDataset: resetDataset,
      deleteDataset: deleteDataset,
      dialogToggle: dialogToggle,
      setTabIndex: setTabIndex,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DatasetDetailPage)
